import { Col, Row, Space } from "antd";
import React from "react";

export const Footer: React.FC = () => {
  return (
    <Row gutter={10}>
      <Col span={24} style={{ alignItems: "center", textAlign: "right" }}>
        <Space style={{ marginRight: 20 }}>
          <p>Министерство юстиции Кыргызской Республики 2022,2023</p>
        </Space>
      </Col>
    </Row>
  );
};
