import React from "react";
import {
  IResourceComponentsProps,
  useApiUrl,
  UseFormProps,
  useTranslate,
} from "@refinedev/core";
import { Create, useForm, getValueFromEvent } from "@refinedev/antd";
import { Form, Input, Upload, Col, Row } from "antd";

// import type { UploadProps } from "@pankod/refine-antd";

import { IBequest, IComponent } from "interfaces";
import { useKeycloak } from "@react-keycloak/web";
import FormElement from "components/FormElement";

export const BequestCreate: React.FC<IResourceComponentsProps> = () => {
  const options: UseFormProps<IBequest> = {};
  const { formProps, saveButtonProps } = useForm<IBequest>(options);
  const apiUrl = useApiUrl();
  const translate = useTranslate();
  const { keycloak } = useKeycloak();
  const { token } = keycloak;

  const t = (key: string) => translate("bequests.fields." + key);
  const components: IComponent[] = [
    {
      component: "input",
      name: "category",
      hidden: true,
      label: t("category"),
      rules: [{ required: true }],
    },
    {
      component: "input",
      name: "bequestNumber",
      label: t("bequestNumber"),
      rules: [{ required: true }, { max: 255 }],
    },
    {
      component: "date",
      name: "bequestDate",
      label: t("bequestDate"),
      rules: [{ required: true }],
    },
    {
      component: "input",
      name: "bequestName",
      label: t("bequestName"),
      rules: [{ required: true }],
    },
    {
      component: "checkbox",
      name: "bequestCancelled",
      label: t("bequestCancelled"),
      rules: [],
    },
    {
      component: "input",
      name: "bequestDistrict",
      label: t("bequestDistrict"),
      rules: [{ required: true }],
    },
  ];
  const initialValues = {
    category: "BEQUEST",
  };
  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" initialValues={initialValues}>
        <Row gutter={20}>
          <Col span={16}>
            {components.map((component) => (
              <FormElement {...component} />
            ))}
            <Form.Item
              name="image"
              valuePropName="fileList"
              getValueFromEvent={getValueFromEvent}
            >
              <Upload.Dragger
                name="file"
                action={`${apiUrl}/media/upload`}
                headers={{ Authorization: `Bearer ${token}` }}
                listType="picture"
                maxCount={50}
                multiple
              >
                <p className="ant-upload-text">
                  {translate(
                    "labels.dragAndDrop",
                    "Drag & drop a file in this area"
                  )}
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Col>
          <Col span={8}>
            {" "}
            <Form.Item
              label={translate("folders.fields.signer", "Signer")}
              name="signer"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={translate("folders.fields.pin", "Pin")}
              name="pin"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
