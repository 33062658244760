import { Layout as AntdLayout, Button } from "antd";
import { useLogin, useTranslate } from "@refinedev/core";

export const Login: React.FC = () => {
  const { mutate: login } = useLogin({
    v3LegacyAuthProviderCompatible: true
  });
  const translate = useTranslate();
  return (
    <AntdLayout
      style={{
        background: `radial-gradient(50% 50% at 50% 50%, #5555af 0%, #111168 100%)`,
        backgroundSize: "cover",
      }}
    >
      <div style={{ height: "100vh", display: "flex-col" }}>
        <div
          style={{
            border: "0px solid green",
            margin: "auto",
            width: "50%",
            color: "white",
            fontFamily: "arial",
            fontWeight: "bold",
            fontSize: "32px",
            textAlign: "center",
            padding: "10px 0 10px 0",
          }}
        >
          <h1 style={{ marginTop: 100 }}>{translate("title", "")}</h1>
        </div>
        <div
          style={{
            maxWidth: "200px",
            margin: "auto",
          }}
        >
          <div
            style={{
              marginTop: "28px",
              marginBottom: "28px",
              marginLeft: "0px",
              border: "0px solid green",
            }}
          >
            <img
              src="/images/logo-mob-big.png"
              alt="Logo"
              style={{
                margin: "0 auto",
                padding: "0 0",
                maxHeight: "200px",
              }}
            />
          </div>

          <div style={{ border: "0px solid green" }}>
            <Button type="primary" size="large" block onClick={() => login({})}>
              {translate("pages.login.signin", "Sign in")}
            </Button>
          </div>
        </div>
      </div>
    </AntdLayout>
  );
};
