import React from "react";
import { IResourceComponentsProps, useApiUrl, UseFormProps, useTranslate } from "@refinedev/core";
import { Create, useForm, getValueFromEvent } from "@refinedev/antd";
import { Form, Input, Upload, Col, Row } from "antd";

// import type { UploadProps } from "@pankod/refine-antd";

import { IInheritance, IComponent } from "interfaces";
import { useKeycloak } from "@react-keycloak/web";
import FormElement from "components/FormElement";

export const InheritanceCreate: React.FC<IResourceComponentsProps> = () => {
  const options: UseFormProps<IInheritance> = {};
  const { formProps, saveButtonProps } = useForm<IInheritance>(options);
  const apiUrl = useApiUrl();
  const translate = useTranslate();
  const { keycloak } = useKeycloak();
  const { token } = keycloak;

  const msgNumbersOnly = translate(
    "validations.numbersOnly",
    "Value should contain just number"
  );

  const t = (key: string) => translate("inheritance.fields." + key);
  const components: IComponent[] = [
    {
      component: "input",
      name: "category",
      hidden: true,
      label: t("category"),
      rules: [{ required: true }],
    },
    {
      component: "input",
      name: "number",
      label: t("number"),
      rules: [{ required: true }, { max: 5 }],
    },
    {
      component: "date",
      name: "receiptDate",
      label: t("receiptDate"),
      rules: [{ required: true }],
    },
    {
      component: "input",
      name: "testator",
      label: t("testator"),
      rules: [{ required: true }],
    },
    {
      component: "date",
      name: "deathDate",
      label: t("deathDate"),
      rules: [{ required: true }],
    },
    {
      component: "input",
      name: "registerInfo",
      label: t("registerInfo"),
      rules: [{ required: false }],
    },
    {
      component: "input",
      name: "heirs",
      label: t("heirs"),
      rules: [{ required: true }],
    },
    {
      component: "input",
      name: "district",
      label: t("district"),
      rules: [{ required: true }],
    },
  ];
  const initialValues = {
    category: "INHERITANCE",
    // signer: "21410197100489",
  };
  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" initialValues={initialValues}>
        <Row gutter={20}>
          <Col span={16}>
            {components.map((component) => (
              <FormElement {...component} />
            ))}

            {/* <Form.Item
              name="category"
              hidden
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={translate("inherticance.fields.number", "Number")}
              name="number"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={translate(
                "inheritance.fields.date1",
                "Дата поступления заявления"
              )}
              name="date1"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              label={translate("inheritance.fields.str1", "ФИО наследников")}
              name="str1"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={translate("inheritance.fields.str2", "ФИО наследодателя")}
              name="str2"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={translate("inheritance.fields.date2", "Дата смерти")}
              name="date2"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              label={translate(
                "inheritance.fields.str3",
                "№ реестра и дата выдачи свидетельства"
              )}
              name="str3"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={translate("inheritance.fields.str4", "Район")}
              name="str4"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
*/}
            <Form.Item
              name="image"
              valuePropName="fileList"
              getValueFromEvent={getValueFromEvent}
            >
              <Upload.Dragger
                name="file"
                action={`${apiUrl}/media/upload`}
                headers={{ Authorization: `Bearer ${token}` }}
                listType="picture"
                maxCount={50}
                multiple
              >
                <p className="ant-upload-text">
                  {translate(
                    "labels.dragAndDrop",
                    "Drag & drop a file in this area"
                  )}
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Col>
          <Col span={8}>
            {" "}
            <Form.Item
              label={translate("folders.fields.signer", "Signer")}
              name="signer"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={translate("folders.fields.pin", "Pin")}
              name="pin"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
