import React from "react";
import { IResourceComponentsProps, useApiUrl, usePermissions, useTranslate } from "@refinedev/core";
import { Edit, getValueFromEvent, useForm } from "@refinedev/antd";
import { Col, Form, Input, Row, Upload } from "antd";

import { IComponent, IInheritance } from "interfaces";
import { useKeycloak } from "@react-keycloak/web";
import FormElement from "components/FormElement";

export const InheritanceEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IInheritance>();
  const { data: permissionsData } = usePermissions({
    v3LegacyAuthProviderCompatible: true
  });
  const isAdmin = permissionsData?.roles?.includes("admin") || false;
  // const postData = queryResult?.data?.data;
  const { keycloak } = useKeycloak();
  const { token } = keycloak;
  const apiUrl = useApiUrl();
  const translate = useTranslate();
  const t = (key: string) => translate("inheritance.fields." + key);
  const components: IComponent[] = [
    {
      component: "input",
      name: "number",
      label: t("number"),
      rules: [{ required: true }, { max: 5 }],
    },
    {
      component: "input",
      name: "receiptDate",
      label: t("receiptDate"),
      rules: [{ required: true }],
    },
    {
      component: "input",
      name: "testator",
      label: t("testator"),
      rules: [{ required: true }],
    },
    {
      component: "input",
      name: "deathDate",
      label: t("deathDate"),
      rules: [{ required: true }],
    },
    {
      component: "input",
      name: "registerInfo",
      label: t("registerInfo"),
      rules: [{ required: false }],
    },
    {
      component: "input",
      name: "heirs",
      label: t("heirs"),
      rules: [{ required: true }],
    },
    {
      component: "input",
      name: "district",
      label: t("district"),
      rules: [{ required: true }],
    },
  ];
  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Row gutter={20}>
          <Col span={16}>
            <Form.Item
              name="category"
              hidden
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            {isAdmin && components.map((component) => (
              <FormElement {...component} />
            ))}
            <Form.Item
              name="image"
              valuePropName="fileList"
              getValueFromEvent={getValueFromEvent}
            >
              <Upload.Dragger
                name="file"
                action={`${apiUrl}/media/upload`}
                headers={{ Authorization: `Bearer ${token}` }}
                listType="picture"
                maxCount={5}
                multiple
              >
                <p className="ant-upload-text">
                  {translate(
                    "labels.dragAndDrop",
                    "Drag & drop a file in this area"
                  )}
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Col>
          <Col span={8}>
            {" "}
            <Form.Item
              label={translate("folders.fields.signer", "Signer")}
              name="signer"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={translate("folders.fields.pin", "Pin")}
              name="pin"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
