import { useState, useEffect } from "react";

import { IResourceComponentsProps, useList, useTranslate } from "@refinedev/core";
import { ShowButton, CreateButton } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Row, Col, Input, Typography, AutoComplete } from "antd";

// import routerProvider from "@pankod/refine-react-router-v6";
import { IInheritance, IOptions } from "interfaces";
import debounce from "lodash/debounce";
import moment from "moment";
// import { Dayjs } from "dayjs";

const { SearchOutlined } = Icons;
const { Text } = Typography;

export const InheritanceSearch: React.FC<IResourceComponentsProps> = () => {
  const renderItem = (record: IInheritance, resource: string) => {
    return (
      <Row
        justify="start"
        align="middle"
        style={{
          padding: "5px 5px 5px 15px",
          marginTop: "10px",
          border: "1px solid",
          borderRadius: "10px",
          borderColor: "gray",
        }}
      >
        <Col span={4}>
          <Text>{record?.number}</Text>
        </Col>
        <Col span={4}>
          <Text>{moment(record?.receiptDate).format("DD.MM.YYYY")}</Text>
        </Col>
        <Col span={4}>
          <Text>{record?.heirs}</Text>
        </Col>
        <Col span={4}>
          <Text>{record?.testator}</Text>
        </Col>
        <Col span={4}>
          <Text>{record?.district}</Text>
        </Col>
        <Col span={4}>
          <ShowButton
            resource="inheritance"
            recordItemId={record?.id}
          ></ShowButton>
        </Col>
      </Row>
    );
  };

  const [value, setValue] = useState<string>("");
  const [options, setOptions] = useState<IOptions[]>([]);
  const translate = useTranslate();
  const { data, refetch: refetchPosts } = useList<IInheritance>({
    resource: "inheritance",

    queryOptions: {
      enabled: false,
      // onSuccess: (data) => {
      // console.log(data.total);
      // const postOptionGroup = data.data.map((item) =>
      //   renderItem(item.number, "folders", item.id)
      // );
      // if (postOptionGroup.length > 0) {
      //   setOptions([
      //     {
      //       label: renderTitle("Documents"),
      //       options: postOptionGroup,
      //     },
      //   ]);
      // }
      // },
    },

    filters: [{ field: "q", operator: "contains", value }]
  });

  useEffect(() => {
    setOptions([]);
    refetchPosts();
  }, [value]);

  const renderFolders = () => {
    if (data) {
      const header = (
        <Row style={{ marginTop: "20px" }}>
          <Col span={4}>{translate("inheritance.fields.number", "Number")}</Col>
          <Col span={4}>
            {translate("inheritance.fields.receiptDate", "Receipt Date")}
          </Col>
          <Col span={4}>{translate("inheritance.fields.heirs", "Heirs")}</Col>
          <Col span={4}>
            {translate("inheritance.fields.testator", "Testator")}
          </Col>
          <Col span={4}>
            {translate("inheritance.fields.district", "District")}
          </Col>
          <Col span={4}>{translate("table.actions", "Actions")}</Col>
        </Row>
      );

      const items = data.data.map((item) => renderItem(item, "folders"));
      if (items.length > 0) {
        return (
          <>
            {header}
            {items}
          </>
        );
      }
    }
  };
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col lg={6} xs={24}>
          <AutoComplete
            style={{ width: "100%", maxWidth: "550px" }}
            filterOption={false}
            options={options}
            onSearch={debounce((value: string) => setValue(value), 500)}
          >
            <Input
              size="large"
              placeholder={translate("document.search.prompt", "TIN, name")}
              suffix={<SearchOutlined />}
            />
          </AutoComplete>
        </Col>
        <Col lg={18} xs={24}>
          <Row justify="end">
            <Col>
              <CreateButton>
                {translate("buttons.create", "Create")}
              </CreateButton>
            </Col>
          </Row>
        </Col>
      </Row>

      {renderFolders()}
    </>
  );
};
