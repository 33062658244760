import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { useTable, List, DateField } from "@refinedev/antd";
import { Button, Form, Input, Table } from "antd";
import { Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
const { Text } = Typography;
interface ISearchForm {
  author: string;
}

export const LogList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable({
    syncWithLocation: true,
    onSearch: (values: ISearchForm) => {
      console.log(values);
      return [
        {
          field: "author",
          operator: "contains",
          value: values.author,
        },
      ];
    },
  });

  const translate = useTranslate();

  return (
    <List title={translate("logs.title", "Logs")}>
      <Form {...searchFormProps} layout="inline">
        <Form.Item name="author">
          <Input placeholder="Пользователь..." />
        </Form.Item>
        <Button
          type="primary"
          onClick={searchFormProps.form?.submit}
          icon={<SearchOutlined />}
        >
          {translate("buttons.search")}
        </Button>
      </Form>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="action"
          title={translate("logs.fields.action", "Action")}
          render={(value: any) => (
            <Text>{translate("logs.actions." + value, value)}</Text>
          )}
        />
        <Table.Column
          dataIndex="resource"
          title={translate("logs.fields.resource", "Resource")}
          render={(value: any) => (
            <Text>{translate("logs.resources." + value, value)}</Text>
          )}
        />
        <Table.Column
          dataIndex="author"
          title={translate("logs.fields.author", "Author")}
        />
        <Table.Column
          dataIndex="number"
          title={translate("logs.fields.number", "Number")}
        />
        <Table.Column
          dataIndex="refId"
          title={translate("logs.fields.id", "ID")}
        />
        <Table.Column
          dataIndex="description"
          title={translate("logs.fields.description", "Description")}
        />
        <Table.Column
          dataIndex={["auditCreated"]}
          title={translate("logs.fields.createdAt", "Created At")}
          render={(value: any) => (
            <DateField value={value} format="DD.MM.YYYY hh:MM:ss" />
          )}
        />
      </Table>
    </List>
  );
};
