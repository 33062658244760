import React from "react";
import {
  IResourceComponentsProps,
  useApiUrl,
  usePermissions,
  useTranslate,
} from "@refinedev/core";
import { Edit, getValueFromEvent, useForm } from "@refinedev/antd";
import { Col, Form, Input, Row, Upload } from "antd";

import { IFolder } from "interfaces";
import { useKeycloak } from "@react-keycloak/web";
export const EntityEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IFolder>();

  const { data: permissionsData } = usePermissions({
    v3LegacyAuthProviderCompatible: true,
  });
  const isNotAdmin = !(permissionsData?.roles?.includes("admin") || false);
  // const postData = queryResult?.data?.data;
  const { keycloak } = useKeycloak();
  const { token } = keycloak;
  const apiUrl = useApiUrl();
  const translate = useTranslate();

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Row gutter={20}>
          <Col span={16}>
            <Form.Item
              name="category"
              hidden={true}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={translate("labels.number", "Number")}
              name="number"
              hidden={isNotAdmin}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={translate("labels.name", "Name")}
              name="name"
              hidden={isNotAdmin}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={translate("labels.tin", "TIN")}
              name="tin"
              hidden={isNotAdmin}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={translate("labels.statcode", "OKPO")}
              name="statno"
              hidden={isNotAdmin}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="image"
              valuePropName="fileList"
              getValueFromEvent={getValueFromEvent}
            >
              <Upload.Dragger
                name="file"
                action={`${apiUrl}/media/upload`}
                headers={{ Authorization: `Bearer ${token}` }}
                listType="picture"
                maxCount={5}
                multiple
              >
                <p className="ant-upload-text">
                  {translate(
                    "labels.dragAndDrop",
                    "Drag & drop a file in this area"
                  )}
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Col>
          <Col span={8}>
            {" "}
            <Form.Item
              label={translate("folders.fields.signer", "Signer")}
              name="signer"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={translate("folders.fields.pin", "Pin")}
              name="pin"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
