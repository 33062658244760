import React from "react";
import {
  IResourceComponentsProps,
  useApiUrl,
  UseFormProps,
  useTranslate,
} from "@refinedev/core";
import { Create, useForm, getValueFromEvent } from "@refinedev/antd";
import { Form, Input, Upload, Col, Row } from "antd";

// import type { UploadProps } from "@pankod/refine-antd";

import { IFolder } from "interfaces";
import { useKeycloak } from "@react-keycloak/web";

export const EntityCreate: React.FC<IResourceComponentsProps> = () => {
  const options: UseFormProps<IFolder> = {
    // errorNotification: (data, values, resource) => {
    //   const response = (data as any).response;
    //   return {
    //     message: `Something went wrong :${JSON.stringify(response.data)}`,
    //     description: "Error",
    //     type: "error",
    //   };
    // },
  };
  const { formProps, saveButtonProps } = useForm<IFolder>(options);
  const apiUrl = useApiUrl();
  const translate = useTranslate();
  const { keycloak } = useKeycloak();
  const { token } = keycloak;
  // const props: UploadProps = {
  //   name: "file",
  //   action: `${apiUrl}/folders/upload`,
  //   headers: {
  //     authorization: "authorization-text",
  //   },
  //   onChange(info) {
  //     if (info.file.status !== "uploading") {
  //       console.log(info.file, info.fileList);
  //     }
  //     if (info.file.status === "done") {
  //       const msg = translate(
  //         "lables.successfulUpload",
  //         "file uploaded successfully"
  //       );
  //       message.success(`${info.file.name} ${msg}`);
  //     } else if (info.file.status === "error") {
  //       message.error(`${info.file.name} file upload failed.`);
  //     }
  //   },
  // };

  const msgNumbersOnly = translate(
    "validations.numbersOnly",
    "Value should contain just number"
  );

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{ category: "ENTITY" }}
      >
        <Row gutter={20}>
          <Col span={16}>
            <Form.Item
              name="category"
              hidden
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={translate("entities.fields.number")}
              name="number"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={translate("entities.fields.name")}
              name="name"
              rules={[
                {
                  required: true,
                },
                { max: 255 },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={translate("entities.fields.tin")}
              name="tin"
              rules={[
                {
                  required: true,
                },
                {
                  pattern: /^(?:\d*)$/,
                  message: msgNumbersOnly,
                },
                { min: 14 },
                { max: 14 },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={translate("entities.fields.statno")}
              name="statno"
              rules={[
                {
                  required: true,
                },
                {
                  max: 30,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="image"
              valuePropName="fileList"
              getValueFromEvent={getValueFromEvent}
            >
              <Upload.Dragger
                name="file"
                action={`${apiUrl}/media/upload`}
                headers={{ Authorization: `Bearer ${token}` }}
                listType="picture"
                maxCount={50}
                multiple
              >
                <p className="ant-upload-text">
                  {translate(
                    "labels.dragAndDrop",
                    "Drag & drop a file in this area"
                  )}
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Col>
          <Col span={8}>
            {" "}
            <Form.Item
              label={translate("folders.fields.signer", "Signer")}
              name="signer"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={translate("folders.fields.pin", "Pin")}
              name="pin"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
