import { Input, InputNumber, Form, Checkbox, DatePicker } from "antd";
import dayjs from "dayjs";
// mapping of our components
const componentMapping = {
  input: Input,
  number: InputNumber,
  password: Input.Password,
  checkbox: Checkbox,
};
export default function FormElement(props) {
  // dinamically select a component from componentMapping object
  const CustomDatePicker = DatePicker;
  // (
  //   <DatePicker defaultValue={dayjs("2015-01-01", "DD-MM-YYYY")} />
  // );
  const Component =
    props.component === "date"
      ? CustomDatePicker
      : componentMapping[props.component];
  return (
    <Form.Item {...props}>
      {props.component === "date" ? (
        <DatePicker format="DD.MM.YYYY" />
      ) : (
        <Component />
      )}
    </Form.Item>
  );
}
