import { useState, useEffect } from "react";

import {
  IResourceComponentsProps,
  useList,
  useTranslate,
} from "@refinedev/core";
import { ShowButton, CreateButton } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Row, Col, Input, Typography, AutoComplete } from "antd";

import { IAlphabet, IOptions } from "interfaces";
import debounce from "lodash/debounce";

const { SearchOutlined } = Icons;
const { Text } = Typography;

export const AlphabetSearch: React.FC<IResourceComponentsProps> = () => {
  const renderItem = (record: IAlphabet) => {
    return (
      <Row
        justify="start"
        align="middle"
        style={{
          padding: "5px 5px 5px 15px",
          marginTop: "10px",
          border: "1px solid",
          borderRadius: "10px",
          borderColor: "gray",
        }}
      >
        <Col span={4}>
          <Text>{record?.alphabetLetter}</Text>
        </Col>
        <Col span={4}>
          <Text>{record?.alphabetYear}</Text>
        </Col>
        <Col span={4}>
          <Text>{record?.alphabetRegion}</Text>
        </Col>
        <Col span={4}>
          <Text>{record?.alphabetDistrict}</Text>
        </Col>
        <Col span={4}>
          <ShowButton
            resource="alphabet"
            recordItemId={record?.id}
          ></ShowButton>
        </Col>
      </Row>
    );
  };

  const [value, setValue] = useState<string>("");
  const [options, setOptions] = useState<IOptions[]>([]);
  const translate = useTranslate();
  const { data, refetch: refetchPosts } = useList<IAlphabet>({
    resource: "alphabet",

    queryOptions: {
      enabled: false,
    },

    filters: [{ field: "q", operator: "contains", value }],
  });

  useEffect(() => {
    setOptions([]);
    refetchPosts();
  }, [value]);

  const renderFolders = () => {
    if (data) {
      const header = (
        <Row style={{ marginTop: "20px" }}>
          <Col span={4}>{translate("alphabet.fields.alphabetLetter")}</Col>
          <Col span={4}>{translate("alphabet.fields.alphabetYear")}</Col>
          <Col span={4}>{translate("alphabet.fields.alphabetRegion")}</Col>
          <Col span={4}>{translate("alphabet.fields.alphabetDistrict")}</Col>
          <Col span={4}>{translate("table.actions", "Actions")}</Col>
        </Row>
      );

      const items = data.data.map((item) => renderItem(item));
      if (items.length > 0) {
        return (
          <>
            {header}
            {items}
          </>
        );
      }
    }
  };
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col lg={6} xs={24}>
          <AutoComplete
            style={{ width: "100%", maxWidth: "550px" }}
            filterOption={false}
            options={options}
            onSearch={debounce((value: string) => setValue(value), 500)}
          >
            <Input
              size="large"
              placeholder={translate("document.search.prompt", "TIN, name")}
              suffix={<SearchOutlined />}
            />
          </AutoComplete>
        </Col>
        <Col lg={18} xs={24}>
          <Row justify="end">
            <Col>
              <CreateButton>
                {translate("buttons.create", "Create")}
              </CreateButton>
            </Col>
          </Row>
        </Col>
      </Row>

      {renderFolders()}
    </>
  );
};
