import React from "react";
import {
  IResourceComponentsProps,
  useApiUrl,
  usePermissions,
  useTranslate,
} from "@refinedev/core";
import { Edit, getValueFromEvent, useForm } from "@refinedev/antd";
import { Col, Form, Input, Row, Upload } from "antd";

import { IBequest, IComponent } from "interfaces";
import { useKeycloak } from "@react-keycloak/web";
import FormElement from "components/FormElement";

export const BequestEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IBequest>();
  const { data: permissionsData } = usePermissions({
    v3LegacyAuthProviderCompatible: true,
  });
  const isAdmin = permissionsData?.roles?.includes("admin") || false;
  // const postData = queryResult?.data?.data;
  const { keycloak } = useKeycloak();
  const { token } = keycloak;
  const apiUrl = useApiUrl();
  const translate = useTranslate();
  const t = (key: string) => translate("bequests.fields." + key);
  const components: IComponent[] = [
    {
      component: "input",
      name: "bequestNumber",
      label: t("bequestNumber"),
      rules: [{ required: true }, { max: 255 }],
    },
    {
      component: "input",
      name: "bequestDate",
      label: t("bequestDate"),
      rules: [{ required: true }],
    },
    {
      component: "input",
      name: "bequestName",
      label: t("bequestName"),
      rules: [{ required: true }],
    },
    {
      component: "checkbox",
      name: "bequestCancelled",
      label: t("bequestCancelled"),
      rules: [],
    },
    {
      component: "input",
      name: "bequestDistrict",
      label: t("bequestDistrict"),
      rules: [{ required: true }],
    },
  ];
  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Row gutter={20}>
          <Col span={16}>
            <Form.Item
              name="category"
              hidden
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            {isAdmin &&
              components.map((component) => <FormElement {...component} />)}
            <Form.Item
              name="image"
              valuePropName="fileList"
              getValueFromEvent={getValueFromEvent}
            >
              <Upload.Dragger
                name="file"
                action={`${apiUrl}/media/upload`}
                headers={{ Authorization: `Bearer ${token}` }}
                listType="picture"
                maxCount={5}
                multiple
              >
                <p className="ant-upload-text">
                  {translate(
                    "labels.dragAndDrop",
                    "Drag & drop a file in this area"
                  )}
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Col>
          <Col span={8}>
            {" "}
            <Form.Item
              label={translate("folders.fields.signer", "Signer")}
              name="signer"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={translate("folders.fields.pin", "Pin")}
              name="pin"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
