import {
  useShow,
  IResourceComponentsProps,
  useTranslate,
  useApiUrl,
  useLog,
} from "@refinedev/core";
import { DateField, Show } from "@refinedev/antd";
import { Button, Col, Row, Typography } from "antd";

import { IFolderObject, IAlienation } from "interfaces";

import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Worker, Viewer, ProgressBar, Plugin } from "@react-pdf-viewer/core";
import { useKeycloak } from "@react-keycloak/web";
import { useState } from "react";
import moment from "moment";

const { Text } = Typography;
// const defaultLayoutPluginProps: DefaultLayoutPluginProps = {};

export const AlienationShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IAlienation>();
  const { data, isLoading } = queryResult;
  const record = data?.data;
  const translate = useTranslate();
  const { keycloak } = useKeycloak();
  const { token } = keycloak;
  const apiUrl = useApiUrl();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const [fileUrl, setFileUrl] = useState("");
  const { log } = useLog();
  const { mutate } = log;
  const onDocumentLoad = (props: any) => {
    // console.log("On document load: %o", props);
  };

  const onDocumentSelect = (object: IFolderObject) => {
    setFileUrl(`${apiUrl}/objects/${object.id}/stream`);
    mutate({
      resource: "objects",
      action: "show",
      data: { id: object.id, description: object.name },
      meta: {
        id: object.id,
      },
    });
  };

  const renderViewer = () => {
    if (fileUrl !== "") {
      return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
          <div
            style={{
              border: "1px solid rgba(0, 0, 0, 0.3)",
              height: "750px",
            }}
          >
            <Viewer
              fileUrl={fileUrl}
              onDocumentLoad={onDocumentLoad}
              httpHeaders={{ Authorization: `Bearer ${token}` }}
              plugins={[defaultLayoutPluginInstance]}
              renderLoader={(percentages: number) => (
                <div style={{ width: "240px" }}>
                  <ProgressBar progress={Math.round(percentages)} />
                </div>
              )}
            />
          </div>
        </Worker>
      );
    } else {
      return <></>;
    }
  };

  const renderItem = (item: IFolderObject) => {
    return (
      <Row style={{ marginTop: "20px" }} key={item.id} gutter={[5, 2]}>
        <Col span={8}>
          <Button
            onClick={() => onDocumentSelect(item)}
            block
            type="primary"
            ghost
            style={{
              whiteSpace: "normal",
              height: "auto",
              marginBottom: "5px",
            }}
          >
            {item.name.length > 50
              ? item.name.substring(0, 50) + "..."
              : item.name}
          </Button>
        </Col>
        <Col span={2}>{item.contentType}</Col>
        <Col span={2}>{item.size}</Col>
        <Col span={4}>{item.signer?.commonName}</Col>
        <Col span={2}>
          <DateField format="DD.MM.YYYY HH:MM:ss" value={item.signedAt} />
        </Col>
      </Row>
    );
  };

  const renderItems = (objects: IFolderObject[]) => {
    const items = objects.map((item) => renderItem(item));

    if (items && items.length > 0) {
      return (
        <>
          <Row style={{ marginTop: "20px" }}>
            <Col span={8}>{translate("objects.fields.name", "Name")}</Col>
            <Col span={2}>
              {translate("objects.fields.contentType", "Content Type")}
            </Col>
            <Col span={2}>{translate("objects.fields.size", "Size")}</Col>
            <Col span={4}>{translate("objects.fields.sign", "Sign")}</Col>
            <Col span={2}>
              {translate("objects.fields.signedAt", "Signed at")}
            </Col>
          </Row>
          {items}
        </>
      );
    } else {
      return <></>;
    }
  };

  const items = record?.objects || [];

  return (
    <Show isLoading={isLoading}>
      <Row gutter={10}>
        <Col span={4}>
          <Text>{translate("alienation.fields.alienationNumber")}</Text>
        </Col>
        <Col span={2}>{translate("alienation.fields.alienationDate")}</Col>
        <Col span={4}>{translate("alienation.fields.alienationFrom")}</Col>
        <Col span={4}>{translate("alienation.fields.alienationTo")}</Col>
        <Col span={4}>{translate("alienation.fields.alienationNotary")}</Col>
      </Row>
      <Row>
        <Col span={4}>
          <Text>{record?.alienationNumber}</Text>
        </Col>
        <Col span={2}>
          {moment(record?.alienationDate).format("DD.MM.YYYY")}
        </Col>
        <Col span={4}>{record?.alienationFrom}</Col>
        <Col span={4}>{record?.alienationTo}</Col>
        <Col span={4}>{record?.alienationNotary}</Col>
      </Row>
      {renderItems(items)}
      {renderViewer()}
    </Show>
  );
};
