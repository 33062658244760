import { Bar, BarConfig } from "@ant-design/plots";
import { IResourceComponentsProps, useList, useTranslate } from "@refinedev/core";
import { ICommonCountStatistics } from "interfaces";

export const DashboardList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { data: response } = useList<ICommonCountStatistics>({
    resource: "dashboard",
  });

  const data: any = response
    ? response.data.map((item) => {
        return { type: t(`resources.${item.type}`), count: item.count };
      })
    : [];

  const config: BarConfig = {
    data,
    xField: "count",
    yField: "type",
    seriesField: "type",
    legend: {
      position: "top-left",
    },
  };
  return <Bar {...config} />;
};
