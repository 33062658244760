import React from "react";

import {
  Refine,
  LegacyAuthProvider as AuthProvider,
  HttpError,
} from "@refinedev/core";
import {
  notificationProvider,
  ReadyPage,
  ErrorComponent,
} from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Spin } from "antd";
import "@refinedev/antd/dist/reset.css";
import { ConfigProvider } from "antd";
import ruRu from "antd/locale/ru_RU";

import dataProvider, { axiosInstance } from "@refinedev/simple-rest";
import routerProvider from "@refinedev/react-router-v6/legacy";
import { useTranslation } from "react-i18next";
import { ColorModeContextProvider } from "contexts";
import {
  Title,
  Header,
  Sider,
  Footer,
  Layout,
  OffLayoutArea,
} from "components/layout";

import { useKeycloak } from "@react-keycloak/web";

import { Login } from "pages/login";
import {
  EntityCreate,
  EntityEdit,
  EntitySearch,
  EntityShow,
} from "pages/entities";
import {
  InheritanceCreate,
  InheritanceEdit,
  InheritanceSearch,
  InheritanceShow,
} from "pages/inheritance";
import {
  BequestCreate,
  BequestEdit,
  BequestSearch,
  BequestShow,
} from "pages/bequests";
import {
  AlienationCreate,
  AlienationEdit,
  AlienationSearch,
  AlienationShow,
} from "pages/alienation";
import { LogList } from "pages/logs/list";
import { DashboardList } from "pages/dashboard";
import {
  AlphabetCreate,
  AlphabetEdit,
  AlphabetSearch,
  AlphabetShow,
} from "pages/alphabet";

const {
  DatabaseOutlined,
  UsergroupAddOutlined,
  UsergroupDeleteOutlined,
  HomeOutlined,
  ContainerOutlined,
  DiffOutlined,
} = Icons;

const apiUrl = `${process.env.REACT_APP_API_URL}`;
const App: React.FC = () => {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const customError: HttpError = {
        ...error,
        message: t(error.response?.data?.data, error.response?.data?.message),
        statusCode: error.response?.status,
      };

      return Promise.reject(customError);
    }
  );

  const { keycloak, initialized } = useKeycloak();

  if (!initialized) {
    return (
      <div
        style={{
          margin: "20px 0",
          marginBottom: "20px",
          padding: "30px 50px",
          textAlign: "center",
          background: "rgba(0, 0, 0, 0.05)",
          borderRadius: "4px",
        }}
      >
        <Spin tip={t("loading", "")} size="large" />
      </div>
    );
  }

  keycloak.onTokenExpired = () => {
    // console.log("token expired", keycloak.token);
    keycloak
      .updateToken(30)
      .then(() => {
        console.log("successfully get a new token at ", new Date());
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const authProvider: AuthProvider = {
    login: async () => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const { to } = Object.fromEntries(urlSearchParams.entries());
      await keycloak
        .login({
          redirectUri: to ? `${window.location.origin}${to}` : undefined,
        })
        .then((data) => {
          console.log("login...", data);
        });
      return Promise.resolve(false);
    },
    logout: async () => {
      await keycloak.logout({
        redirectUri: window.location.origin,
      });
      return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: async () => {
      try {
        const { token } = keycloak;
        // console.log('Check auth... %o %o', token, keycloak);
        if (token) {
          axiosInstance.defaults.headers.common = {
            Authorization: `Bearer ${token}`,
          };
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      } catch (error) {
        return Promise.reject();
      }
    },
    getPermissions: () => {
      console.log("getPermissions....");
      const parsedUser = keycloak?.tokenParsed;
      console.log(parsedUser);
      if (parsedUser) {
        return Promise.resolve(parsedUser.realm_access);
      }
      return Promise.reject();
    },
    getUserIdentity: async () => {
      if (keycloak?.tokenParsed) {
        return Promise.resolve({
          name: keycloak.tokenParsed.family_name,
        });
      }
      return Promise.reject();
    },
  };

  return (
    <ConfigProvider locale={ruRu}>
      <ColorModeContextProvider>
        <Refine
          LoginPage={Login}
          legacyAuthProvider={authProvider}
          accessControlProvider={{
            can: async ({ action, params, resource }) => {
              console.log(
                `Action: ${action}. Resource: ${resource}. Params: %o`,
                params
              );

              if (resource === "dashboard") {
                return Promise.resolve({
                  can: true,
                });
              }
              const realmRole = keycloak.hasRealmRole(`${resource}_${action}`);
              const resourceRole = keycloak.hasResourceRole(
                `${resource}_${action}`
              );

              return Promise.resolve({
                can: realmRole || resourceRole,
              });
            },
          }}
          legacyRouterProvider={{
            ...routerProvider,
            // routes: [
            //   {
            //     element: <HomePage />,
            //     path: "/home",
            //     layout: true,
            //   },
            // ],
          }}
          dataProvider={dataProvider(`${apiUrl}`, axiosInstance)}
          auditLogProvider={{
            get: async ({ resource, meta }) => {
              const { data } = await dataProvider(apiUrl).getList({
                resource: "logs",
                filters: [
                  {
                    field: "resource",
                    operator: "eq",
                    value: resource,
                  },
                  {
                    field: "meta.id",
                    operator: "eq",
                    value: meta?.id,
                  },
                ],
              });

              return data;
            },
            create: (params) => {
              return dataProvider(apiUrl).create({
                resource: "logs",
                variables: params,
              });
            },
            update: async ({ id, name }) => {
              const { data } = await dataProvider(apiUrl).update({
                resource: "logs",
                id,
                variables: { name },
              });
              return data;
            },
          }}
          notificationProvider={notificationProvider}
          ReadyPage={ReadyPage}
          catchAll={<ErrorComponent />}
          resources={[
            {
              name: "dashboard",
              meta: { label: t("dashboard.title", "Dashboard") },
              icon: <HomeOutlined />,
              list: DashboardList,
            },
            {
              name: "entities",
              meta: { label: t("folders.title", "Folders") },
              icon: <DatabaseOutlined />,
              list: EntitySearch,
              edit: EntityEdit,
              show: EntityShow,
              create: EntityCreate,
              canDelete: true,
            },
            {
              name: "inheritance",
              meta: { label: t("inheritance.title", "Inheritance") },
              icon: <ContainerOutlined />,
              list: InheritanceSearch,
              edit: InheritanceEdit,
              show: InheritanceShow,
              create: InheritanceCreate,
              canDelete: true,
            },
            {
              name: "bequests",
              meta: { label: t("bequests.title") },
              icon: <UsergroupAddOutlined />,
              list: BequestSearch,
              edit: BequestEdit,
              show: BequestShow,
              create: BequestCreate,
              canDelete: true,
            },
            {
              name: "alienation",
              meta: { label: t("alienation.title") },
              icon: <UsergroupDeleteOutlined />,
              list: AlienationSearch,
              edit: AlienationEdit,
              show: AlienationShow,
              create: AlienationCreate,
              canDelete: true,
            },
            {
              name: "alphabet",
              meta: { label: t("alphabet.title") },
              icon: <UsergroupDeleteOutlined />,
              list: AlphabetSearch,
              edit: AlphabetEdit,
              show: AlphabetShow,
              create: AlphabetCreate,
              canDelete: true,
            },
            {
              name: "logs",
              meta: { label: t("logs.title", "Logs") },
              icon: <DiffOutlined />,
              list: LogList,
              // show: AntdInferencer,
            },
          ]}
          Title={Title}
          Header={Header}
          Sider={Sider}
          Footer={Footer}
          Layout={Layout}
          OffLayoutArea={OffLayoutArea}
          i18nProvider={i18nProvider}
        />
      </ColorModeContextProvider>
    </ConfigProvider>
  );
};

export default App;
