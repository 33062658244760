import React from "react";
import { createRoot } from "react-dom/client";

import reportWebVitals from "./reportWebVitals";
import App from "./App";
import "./i18n";
import Keycloak from "keycloak-js";
import { ReactKeycloakProvider } from "@react-keycloak/web";

const container = document.getElementById("root") as HTMLElement;

const keycloak = new Keycloak({
  clientId: `${process.env.REACT_APP_AUTH_CLIENT_ID}`,
  url: `${process.env.REACT_APP_AUTH_URL}`,
  realm: `${process.env.REACT_APP_AUTH_REALM}`,
});

const root = createRoot(container);

root.render(
  <ReactKeycloakProvider authClient={keycloak}>
    <App />
  </ReactKeycloakProvider>
);

// root.render(
//   <React.StrictMode>
//     <React.Suspense fallback="loading">
//       <App />
//     </React.Suspense>
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
